import React from 'react'
import {Link} from 'gatsby'
import {buildImageObj, getTimelineUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import Carousel from './carousel'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import nextimg from '../images/next.svg'
import previmg from '../images/prev.svg'
import imgnav from '../images/imgnav.png'

import styles from './timeline.module.css'

function Timeline (props) {
  const {_rawBody, gallery, title, mainImage, startDate, endDate} = props[0]
  const prev = props[1]
  const next = props[2]
  return (
    <article className={styles.root}>
      {/* <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/timeline/">
            Linha do tempo
          </Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb> */}
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1920)
              .height(Math.floor((9 / 16) * 1920))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
       
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
              <div className={styles.portable}>{_rawBody && <PortableText blocks={_rawBody} />}</div>
          </div>
        </div>
      </Container>

      <div className={styles.carousels}>
        <div className={styles.galeria}>
          {gallery && <Carousel data={gallery}/>}
        </div>
      </div>

      <div className={styles.break}>
        <hr/>
        <Link to='/timeline'>Linha do tempo</Link>
        <hr/>
      </div>

      <div className={styles.links}>
        <div className={styles.prev}>{prev && <Link to={getTimelineUrl(prev.slug.current)}><img src={previmg} alt="Tópico anterior" /><p>Voltar</p></Link>}</div>
        <div className={styles.next}>{next && <Link to={getTimelineUrl(next.slug.current)}><p>{next.title}</p><img src={nextimg} alt="Próxima época"/></Link>}</div>
      </div>

      <div className={styles.imgnav}>
        <img src={imgnav} alt="Ganhadeiras"/>
      </div>
    </article>
  )
}

export default Timeline
